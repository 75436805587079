<template>
  <div class="design"> 
    <div class="design-main">
      <div class="content">
        <div class="desc">
          <div class="title">{{cmsHelp.title}}</div>
         <div class="text">
          <div class="r" id="zongjingli">
           <!-- {{cmsHelp.createDate}}    <br /> -->
            </div>
          </div>
          <div class="text" v-html="cmsHelp.yzCmsHelpData.cmsAskDataContent"> </div>    
            
          
          <div class="text">
          <div class="r" id="zongjingli">
              <!-- 惠享网招商部负责人：韩经理 <br />
              联系电话：0431-8088 7187 -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
 data() {
    return {cmsHelp: { "yzCmsHelpData": {"cmsAskDataContent": ""}}

    };
  },methods: {
    getCMSHelpDetail() {
      this.loading = true;
      this.$api
        .get("/api/v1/getYzCmsHelpView", {
          params: {
            id: `${this.$route.query.id}`
          },
        })
        .then((res) => {
          this.loading = false;
           this.cmsHelp=res.data;
         if (res.code != 1) {
            this.$message({
            message: res.message,
            type: res.code == 1 ? "success" : "error",
            duration: 1500,
          });
          }
        })
        .catch((err) => {
          this.loading = false;
        });
    },
  },
  mounted() {
 
   setTimeout(() => {
      this.$store.commit("anime");
    }, 300);   
    this.$parent.finish ? this.$parent.finish() : null;
     this.getCMSHelpDetail();
  }
};
</script>
<style lang="scss">
.design {
  .banner {
    img {
      width: 100%;
      display: block;
    }
  }
  .design-main {
    margin: 50px auto;
    .desc {
      border: solid 1px #f2f2f2;
    }
    .title {
      background-color: #f2f2f2;
      line-height: 50px;
      font-size: 22px;
      text-align: center;
    }
    .text {
      padding: 30px;
      font-size: 16px;
      line-height: 2;
      .r {
        text-align: right;
      }
    }
  }
}
</style>